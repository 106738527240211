import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Backdrop,
	TextField,
	Typography,
	IconButton,
	CircularProgress,

	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	ToggleButton,
	ToggleButtonGroup,
} from "@mui/material";
import {
	Delete as DeleteIcon
} from "@mui/icons-material";
import {
	makeStyles
} from "@mui/styles";
import {
	FileUploader,
	DialogConfirmation
} from "../../../../../components";
import {
	Notification,
	notificationTypes
} from "../../../../../common/Notification";
import agent from "../../../../../agent/agent";

const DiagnosticsInfo = (props) => {
	const {
		application,
		isFullDisabled,
		onUpdateApplication
	} = props;
	const classes = useStyles();

	const [diagnostic, setDiagnostic] = React.useState(application?.diagnostic || null);
	const isNotDiagnostic = Boolean(!diagnostic);

	React.useEffect(() => {
		setDiagnostic(application?.diagnostic || null);
	}, [application]);

	const [isOpenPopUp, setOpenPopUp] = React.useState(false);
	const handleChangeOpenPopUp = (_val) => {
		setOpenPopUp(_val);
	}

	const [isBackdrop, setBackdrop] = React.useState(false);
	const handleSubmitDiagnostic = async ({ type, value }) => {
		setBackdrop(true);

		const formData = new FormData();
		if (type === 'file') {
			formData.append("file", value)
		}
		if (type === 'link') {
			formData.append("url", value)
		}

		let errorMessage = "";
		const isSuccess = await agent.post(`/orders/diagnostic/${ application.id }`, formData)
			.then(() => true)
			.catch((err) => {
				errorMessage = err?.response?.data?.message || ""
				return false
			})

		await onUpdateApplication();

		Notification({
			type: !isSuccess ? notificationTypes.error : notificationTypes.success,
			message: !isSuccess ? (errorMessage || 'Ошибка загрузки диагностики') : 'Диагностика успешно загружена'
		});

		setOpenPopUp(false);
		setBackdrop(false);
	};

	const refDialogConfirmation = React.createRef();
	const handleRemoveDiagnostic = async (isConfirm, diagnosticId) => {
		if (!isConfirm) {
			refDialogConfirmation.current.onOpen({
				message: "Вы действительно хотите удалить диагностику?",
				acceptButtonTitle: "Да, удалить",
				acceptButtonAction: handleRemoveDiagnostic.bind(this, true, diagnosticId)
			})
			return
		}
		setBackdrop(true);
		const isSuccess = await agent.delete(`/orders/diagnostic/${ application.id }/${ diagnosticId }`)
			.then(() => true)
			.catch(() => false)
		if (!isSuccess) {
			setBackdrop(false);
			Notification({
				type: notificationTypes.error,
				message: "Ошибка удаления диагностики"
			})
			return
		}
		await onUpdateApplication();
		setBackdrop(false);
		Notification({
			type: notificationTypes.success,
			message: "Диагностика успешно удалена"
		})
	};

	const handleClickDiagnostic = (diagnostic) => {
		window.open(diagnostic?.url, '_blank');
	}

	return (
		<Box>
			<Typography variant="h3" textAlign="left" mb={1}>Диагностика</Typography>

			<VisibleContent visible={Boolean(isNotDiagnostic)}>
				<Typography variant="caption" mb={0.5} sx={{justifyContent: "flex-start"}}>Нет диагностического файла</Typography>
				<Button disabled={isFullDisabled} variant="outlined" size="small" onClick={handleChangeOpenPopUp.bind(this, true)}>
					Добавить файл
				</Button>
			</VisibleContent>
			<VisibleContent visible={Boolean(!isNotDiagnostic)}>
				<Grid container spacing={1}>
					{(application?.diagnostic || []).map((diagnostic, index) => (
						<Grid item xs={12}>
							<Grid container spacing={0.5}>
								<Grid item>
									<Tooltip classes={{
										popper: classes.tooltip
									}} title={<React.Fragment>
										Открыть диагностику в новой вкладке<br/>
										<span className={classes.tooltipSpan}>{diagnostic?.url || "Н/Д"}</span>
									</React.Fragment>} arrow>
										<IconButton color="primary"  sx={{border: "1px solid", borderRadius: "4px", padding: "0 24px", height: 38}} onClick={handleClickDiagnostic.bind(this, diagnostic)}>
											<span style={{fontSize: 16, fontWeight: "600"}}>Открыть диагностику</span>
										</IconButton>
									</Tooltip>
								</Grid>
								<Grid item>
									<Tooltip title="Удалить диагностику" arrow>
										<IconButton color="primary"  sx={{border: "1px solid", borderRadius: "4px", padding: "6px"}} onClick={handleRemoveDiagnostic.bind(this, false, diagnostic.diagnostic_id)}>
											<DeleteIcon sx={{fontSize: 24}}/>
										</IconButton>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					))}
				</Grid>
				<Box mt={1}/>
				<Button variant="outlined" size="small" onClick={handleChangeOpenPopUp.bind(this, true)}>
					Добавить файл
				</Button>
			</VisibleContent>

			<VisibleContent visible={isOpenPopUp}>
				<DialogSelectDiagnostic
					onClose={handleChangeOpenPopUp.bind(this, false)}
					onSubmit={handleSubmitDiagnostic}
				/>
			</VisibleContent>


			<Backdrop open={isBackdrop}>
				<CircularProgress/>
			</Backdrop>
			<DialogConfirmation ref={refDialogConfirmation}/>
		</Box>
	)
};
const DialogSelectDiagnostic = (props) => {
	const {
		onClose,
		onSubmit
	} = props;
	const [type, setType] = React.useState('link');
	const [fileLink, setFileLink] = React.useState('');
	const [fileBlob, setFileBlob] = React.useState(null);

	const handleChangeFileLink = ({ target }) => {
		setFileLink(target?.value || '')
	}
	const handleChangeFileBlob = (event) => {
		const file = event?.target?.value?.file || null;
		setFileBlob(file);
	}

	const handleSubmit = () => {
		const _val = Boolean(type === 'link') ? fileLink : fileBlob;
		onSubmit({
			type,
			value: _val
		})
	}

	return (
		<Dialog
			open
			fullWidth
			maxWidth="md"
			onClose={onClose}
		>
			<DialogTitle>
				<Typography variant="h3" textAlign="left">Загрузка диагностики</Typography>
			</DialogTitle>
			<DialogContent>
				<ToggleButtonGroup
					size="small"
					color="primary"
					value={type}
					exclusive={true}
					onChange={(e, v) => setType(v)}
				>
					<ToggleButton value="link" sx={{height: "auto", padding: "2px 20px", fontSize: 14}}>Ссылку на файл</ToggleButton>
					<ToggleButton value="file" sx={{height: "auto", padding: "2px 20px", fontSize: 14}}>Прикрепить файл</ToggleButton>
				</ToggleButtonGroup>

				<Box mt={2}/>

				<VisibleContent visible={Boolean(type === 'link')}>
					<TextField
						value={fileLink}
						size="small"
						placeholder="Ссылка на файл"
						fullWidth={true}
						onChange={handleChangeFileLink}
					/>
				</VisibleContent>
				<VisibleContent visible={Boolean(type === 'file')}>
					<FileUploader
						value={fileBlob}
						name="files"
						accept={{
							"image/gif": [ ".gif" ],
							"image/jpeg": [ ".jpeg", ".jpg" ],
							"image/png": [ ".png" ],
							"image/webp": [ ".webp" ],

							"video/mpeg": [ ".mpeg" ],
							"video/mp4": [ ".mp4" ],
							"video/ogg": [ ".ogv" ],
							"video/webm": [ ".webm" ],
							"video/x-msvideo": [ ".avi" ],
							"video/3gpp": [ ".3gp" ],
							"video/3gpp2": [ ".3g2" ],
							"video/quicktime": [ ".quicktime" ],

							'application/pdf': ['.pdf'],
							'application/doc': ['.doc'],
							'application/docx': ['.docx'],
						}}

						onChange={handleChangeFileBlob}
						onDeleteWorkFile={handleChangeFileBlob}
					/>
				</VisibleContent>

			</DialogContent>
			<DialogActions>
				<Box px={2} pb={1}>
					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="outlined" onClick={onClose}>
								Отменить
							</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" onClick={handleSubmit}>
								Загрузить
							</Button>
						</Grid>
					</Grid>
				</Box>
			</DialogActions>
		</Dialog>
	)
};
const VisibleContent = ({ visible, children }) => {
	if (!visible) {
		return null
	}
	return children
}

const useStyles = makeStyles(() => ({
	tooltip: {
		pointerEvents: "none"
	},
	tooltipSpan: {
		fontSize: 14,
		lineHeight: 1.2,
		whiteSpace: "nowrap",

		marginTop: 4,
		overflow: "hidden",
			display: "-webkit-box",
			"-webkit-line-clamp": "1",
			"-webkit-box-orient": "vertical",
	}
}));

export default DiagnosticsInfo
