import React, {Component} from "react";
import {
	Box,
	Grid,
	Button,
	TextField,
	Typography,
	FormHelperText,

	Backdrop,
	CircularProgress
} from "@mui/material";
import {
	withStyles
} from "@mui/styles";
import {
	Formik
} from "formik";
import {
	Notification,
	notificationTypes
} from "../../../common/Notification";
import {
	Link
} from "react-router-dom";
import * as Yup from "yup";
import agent from "../../../agent/agent";
import {IMaskInput} from "react-imask";

class AuthorizationLogPass extends Component {
	constructor(props) {
		super(props);

		this.state = {
			form: {
				email: "",
				password: ""
			},

			step: 0,

			isShowBackdrop: false
		};

		this.refFormik = React.createRef();
	}

	submitConfirmationAccess = async (values) => {
		this.setState({ isShowBackdrop: true });

		const response = await agent.post(`/auth/login-diagnostic`, {...values}).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isShowBackdrop: false });
			Notification({
				type: notificationTypes.error,
				message: response.error?.data?.details || "Ошибка сервера"
			})

			return null
		}

		agent.defaults.headers["x-auth-token"] = response.accessToken;
		localStorage.setItem('accessToken', response.accessToken);
		await this.props.setUser();
		this.setState({ isShowBackdrop: false });
	}

	changeForm = ({ target }) => {
		const { value, name } = target;

		let values = this.refFormik.current.values;
		values[name] = value;
		this.refFormik.current.setValues(values);
	}

	render() {
		const { classes } = this.props;
		const {
			form,
			step,
			isShowBackdrop
		} = this.state;

		return (
			<Box className={classes.root}>

				<Formik
					innerRef={this.refFormik}
					initialValues={form}
					validationSchema={validationSchema}
					onSubmit={this.submitConfirmationAccess}
				>{(props) => {
					const {
						values,
						errors,
						touched,
						handleSubmit
					} = props;

					return (
						<Grid
							container
							spacing={2}
						>
							<Grid item xs={12}>
								<Typography variant="h1" textAlign="center">Авторизация диагноста</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.email}

									name="email"
									label="Email"
									color="secondary"
									variant="outlined"
									size="small"
									autoFocus

									fullWidth={true}
									error={Boolean(touched['email'] && errors['email'])}
									helperText={touched.email && errors.email}

									onChange={this.changeForm}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									value={values.password}

									name="password"
									label="Пароль"
									color="secondary"
									variant="outlined"
									size="small"

									fullWidth={true}
									error={Boolean(touched['password'] && errors['password'])}
									helperText={touched.password && errors.password}

									onChange={this.changeForm}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
									Войти
								</Button>
							</Grid>
						</Grid>
					)
				}}</Formik>

				<Box className={classes.authLogPass}>
					<Button component={Link} to="/authorization">
						Вход для механиков
					</Button>
				</Box>


				<Backdrop open={isShowBackdrop}>
					<CircularProgress/>
				</Backdrop>

			</Box>
		);
	}
}

const styles = {
	root: {
		maxWidth: 640,
		margin: "0 auto",
		position: "relative",

		padding: 20,
		boxSizing: "border-box",
		backgroundColor: "white",
		borderRadius: 14,
		borderBottomRightRadius: 0,
		border: "1px solid #F0F0F0",
	},
	authLogPass: {
		padding: 4,
		borderRadius: "0 0 8px 8px",
		border: "1px solid #F0F0F0",
		borderTop: "none",
		top: "100%", right: "-1px",
		position: "absolute",
		boxSizing: "border-box",
		backgroundColor: "white",

		"& .MuiButton-root": {
			fontSize: 14,
			lineHeight: 1.1,
			height: "auto",
			padding: "4px 12px",
		}
	}
};
AuthorizationLogPass = withStyles(styles)(AuthorizationLogPass);

const validationSchema = Yup.object().shape({
	password: Yup.string().required('Заполните поле'),
	email: Yup.string().required('Заполните поле'),
});

export default AuthorizationLogPass
